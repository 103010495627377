import React, { HtmlHTMLAttributes, useEffect, useRef, useState } from "react"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import ReactDOM from 'react-dom'
import { EventEmitter, LoadWidget, isIOS, isPc, random } from "@mk/utils"
import clas from "classnames"
import { cdnApi, getPermissionData } from "@mk/services"
import { LayerElemItem } from "@mk/works-store/types"
import { deepLayers } from "@mk/works-store/utils/deepLayers"
import { MkGridProps, MkGridState } from '../shared'
import { deepClone, blockStyleFilter, cleanGridProps } from "../shared/utils"

import "./index.scss"
import MkImgLiteComp from "./MkImgLiteComp"

interface ChangeItemAttrParams {
  elemId: string;
  nextAttrs: any;
}

const Indicator = ({ domID = '', title }) => {
  const targetDOMRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (domID) {
      targetDOMRef.current = document.querySelector(domID) as HTMLDivElement
    }
  }, [])
  return (
    <div className="grid_indicator">
      {title} {targetDOMRef.current?.clientWidth}px {targetDOMRef.current?.clientHeight}px
    </div>
  )
}

const MkGrid: React.FC<PlatformCompProps<MkGridProps, MkGridState>> = (props) => {
  const {
    lifecycle: { didMount, didLoaded }, canvaInfo, controledValues, readonly,
    editorSDK, containerInfo, pageInfo, editorCtx, worksType, getWorksData, id,
    widgetState,
  } = props

  const interactive = !!editorSDK && !readonly
  const fullStack = getPermissionData().isDesignerRole && interactive

  const { activeCellIdx, activeRowIdx, editingElemId, isActiveGrid } = widgetState || {}
  const MkGridRef = useRef<HTMLDivElement>(null)
  const { cells, cellsMap: _cellsMap, style: gridStyle = {}, isBlockMode = worksType === 'longH5' } = controledValues
  const cellsMap = cleanGridProps(_cellsMap)

  const getLayer = (elemId) => {
    if (!getWorksData) return
    const worksData = getWorksData()
    const getAllLayers = (withFixedLayer = false) => {
      const { pages, fixedLayer } = worksData.canvasData.content
      const layersItemArray: LayerElemItem[] = []
      for (const page of pages) {
        const { layers } = page
        deepLayers(layers, (item) => {
          layersItemArray.push(item)
        })
      }
      if (withFixedLayer) {
        deepLayers(fixedLayer, (item) => {
          layersItemArray.push(item)
        })
      }
      return layersItemArray
    }
    const layers = getAllLayers()
    for (let idx = 0;idx < layers.length;idx++) {
      const element = layers[idx]
      if (element.elemId === elemId) {
        return element
      }
    }
  }

  const deleteComp = (rowIndex, colIndex, elemId) => {
    const nextCells = deepClone([...cellsMap])
    const childIdx = nextCells[rowIndex].cells[colIndex].childrenIds?.indexOf(elemId)
    if (typeof childIdx === 'undefined' || childIdx === -1) return
    nextCells[rowIndex].cells[colIndex].childrenIds?.splice(childIdx, 1)
    editorSDK?.deleteCompEntity(elemId)

    if (nextCells[rowIndex].cells[colIndex].childrenIds?.length === 0) {
      const nextCells = deepClone([...cellsMap])
      nextCells[rowIndex].cells.splice(colIndex, 1)
      editorSDK?.changeWidgetState({
        editingElemId: undefined,
        activeCellIdx: undefined,
        activeRowIdx: undefined,
        isActiveGrid: false,
      })
      editorSDK?.onFormValueChange({
        cellsMap: nextCells
      })
    }
  }

  useEffect(() => {
    if (!interactive) return
    /** 快捷键绑定 */
    function KeyCheck(event: KeyboardEvent) {
      const k = event.key
      if (event.shiftKey && k === 'Backspace') {
        if (activeCellIdx && editingElemId) {
          event.preventDefault()
          event.stopPropagation()
          deleteComp(activeRowIdx, activeCellIdx, editingElemId)
        } else if (activeRowIdx && activeCellIdx && !editingElemId) {
          event.preventDefault()
          event.stopPropagation()
          const nextCells = deepClone([...cellsMap])
          nextCells[activeRowIdx].cells.splice(activeCellIdx, 1)
          editorSDK?.changeWidgetState({
            editingElemId: undefined,
            activeCellIdx: undefined,
            activeRowIdx: undefined,
            isActiveGrid: false,
          })
          editorSDK?.onFormValueChange({
            cellsMap: nextCells
          })
        }
      }
    }
    document.addEventListener("keydown", KeyCheck)

    return () => {
      document.removeEventListener("keydown", KeyCheck)
    }
  }, [activeCellIdx, activeRowIdx, editingElemId])

  useEffect(() => {
    /** 宽高重算逻辑 */
    if (!MkGridRef.current || readonly || !editorSDK) return

    const resetContainer = () => {
      if (!MkGridRef.current) return
      const gridHeight = MkGridRef.current.getBoundingClientRect().height +
        +MkGridRef.current.style.marginBottom.replace('px', '') +
        +MkGridRef.current.style.marginTop.replace('px', '')
      const gridWidth = MkGridRef.current.getBoundingClientRect().width +
        +MkGridRef.current.style.marginLeft.replace('px', '') +
        +MkGridRef.current.style.marginRight.replace('px', '')
      const isHeightChanged = Math.abs(gridHeight - pageInfo.height) > 5
      const isDiffWidth = Math.abs(gridWidth - pageInfo.width) > 1
      if (isBlockMode) {
        if (isHeightChanged || containerInfo.x !== 0 || containerInfo.y !== 0 || isDiffWidth) {
          editorSDK?.changeContainer({
            height: gridHeight,
            width: pageInfo?.width,
            x: 0,
            y: 0,
          })
        }
        if (isHeightChanged && typeof pageInfo?.pageIndex !== 'undefined' && gridHeight > 0) {
          editorSDK?.changePageScale(pageInfo.pageIndex, {
            height: gridHeight,
          })
        }
      } else {
        const realH = gridHeight / canvaInfo.scaleRate
        editorSDK?.changeContainer({
          ...containerInfo,
          height: realH,
        })
      }
    }

    setTimeout(() => {
      resetContainer()
    }, 100 + pageInfo.pageIndex * 100)

    const observer = new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation) => {
        // console.log(mutation)
      })
      // const clientHeight = MkGridRef.current?.clientHeight
      // console.log('clientHeight', clientHeight, JSON.parse(JSON.stringify(containerInfo)))
      resetContainer()
    })

    observer.observe(MkGridRef.current, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [MkGridRef.current, containerInfo])

  useEffect(() => {
    if (editorSDK && isBlockMode) {
      if (editorSDK.getActivePageIdx() === pageInfo.pageIndex) {
        editorSDK.selectLayer?.(id)
      }
    }
    return () => {
    }
  }, [editorSDK?.getActivePageIdx()])


  // console.log('controledValues', controledValues)
  useEffect(() => {
    /** 用于在编辑器内挂载完成的回调 */
    const handleCancelChoose = () => {
      editorSDK?.changeWidgetState({
        editingElemId: undefined,
        activeCellIdx: undefined,
        activeRowIdx: undefined,
        isActiveGrid: false,
      })
    }
    const currLink = editorSDK?.getLink(id)

    EventEmitter.on('clearWidgetActiveStatus', handleCancelChoose)

    didMount({
      boxInfo: {
        width: canvaInfo.canvaW,
        height: currLink?.height || 300,
      },
      data: {
        cellsMap: [
          {
            cells: [{}, {}],
            style: {}
          },
        ]
      }
    })

    /** 用于在 viewer 广播的组件加载完成事件 */
    didLoaded()

    if (!cellsMap && cells) {
      /** 将 cells 转换成 cellsMap */
      const nextCellsMap = cells.map((rowItems) => {
        const nextRowItems = rowItems.map((colItem) => {
          return {
            ...colItem,
            childrenIds: colItem.childrenIds || []
          }
        })
        return {
          cells: nextRowItems,
          style: {}
        }
      })

      editorSDK?.onFormValueChange({
        cellsMap: nextCellsMap
      })
    }

    return () => {
      EventEmitter.rm('clearWidgetActiveStatus', handleCancelChoose)
    }
  }, [])

  useEffect(() => {
    const setLayerInGridDisabled = () => {
      /** 将子元素全部设置为 disabled 状态 */
      if (isBlockMode && editorSDK && !readonly) {
        const allLayerIds: Array<{ compEntityId: string; option: { disabled: boolean } }> = []
        cellsMap?.forEach((rowItem) => {
          rowItem.cells.forEach((colItem) => {
            colItem.childrenIds?.forEach((childId) => {
              if (!editorSDK.getLink(childId)?.disabled) {
                allLayerIds.push({
                  compEntityId: childId,
                  option: {
                    disabled: true
                  }
                })
              }
            })
          })
        })

        // console.log('allLayerIds', allLayerIds)
        if (allLayerIds.length > 0) {
          editorSDK?.setLayerDisableStateBatch(allLayerIds)
        }
      }
    }

    const cleanLayerInGrid = () => {
      /** 将没有在 grid 中且状态是 disabled 的 layer 删掉 */
      if (isBlockMode && editorSDK && !readonly) {
        const needDeleteIds: string[] = []
        editorSDK?.getPageData(pageInfo.pageIndex)?.layers?.forEach((layer) => {
          const layerLink = editorSDK.getLink(layer.elemId)
          const isLayerInGrid = cellsMap?.some((rowItem) => {
            return rowItem.cells.some((colItem) => {
              return colItem.childrenIds?.includes(layer.elemId)
            })
          })
          if (!isLayerInGrid && (layerLink.disabled || (!layerLink.visibility && layerLink.lock))) {
            needDeleteIds.push(layer.elemId)
          }
        })

        if (needDeleteIds.length > 0) {
          editorSDK?.deleteCompEntity(needDeleteIds, pageInfo.pageIndex)
        }
      }
    }

    const setPageDisableOption = () => {
      if (editorSDK && !readonly && !pageInfo.options?.disabledPageOptions) {
        editorSDK.setPageOptions(pageInfo.pageIndex, {
          disabledPageOptions: true
        })
      }
    }

    const lockImgRatio = async () => {
      if (editorSDK && !readonly) {
        const asyncGetImgRatioQueue: Promise<ChangeItemAttrParams>[] = []
        cellsMap?.forEach((rowItem) => {
          rowItem.cells.forEach((colItem) => {
            colItem.childrenIds?.forEach((childId) => {
              const currLayer = editorSDK.getLayer(childId)
              if (currLayer && currLayer.elementRef === 'MkPicture' && (!currLayer.attrs?.lockRatioV2 || !currLayer.attrs?.originOssPath)) {
                const currAttr = currLayer.attrs
                const getImgRatio = new Promise<ChangeItemAttrParams>((resolve, reject) => {
                  const img = new Image()
                  img.src = cdnApi(currAttr.ossPath)
                  img.onload = () => {
                    const nextData = {
                      elemId: childId,
                      nextAttrs: {
                        lockRatioV2: +(img.width / img.height).toFixed(5),
                        baseW: img.width,
                        baseH: img.height,
                        originBaseW: img.width,
                        originBaseH: img.height,
                        originOssPath: currAttr.ossPath,
                        cropData: {
                          left: 0,
                          top: 0,
                          width: img.width,
                          height: img.height,
                        }
                      }
                    }
                    resolve(nextData)
                  }
                  img.onerror = () => {
                    reject()
                  }
                })
                asyncGetImgRatioQueue.push(getImgRatio)
              }
            })
          })
        })
        const imgLayerIds = await Promise.all(asyncGetImgRatioQueue)

        if (imgLayerIds.length > 0) {
          editorSDK?.changeCompAttrMulti(imgLayerIds)
        }
      }
    }

    setPageDisableOption()
    setLayerInGridDisabled()
    // cleanLayerInGrid()
    lockImgRatio().catch(err => {
      console.log('lockImgRatio err', err)
    })

    return () => {

    }
  }, [])

  useEffect(() => {
    // 根据 canvaInfo.scaleRate 设置 --scaleRate 变量
    document.body.style.setProperty('--scaleRate', `${canvaInfo.scaleRate}`)

    return () => {
    }
  }, [canvaInfo.scaleRate])


  useEffect(() => {
    if (!isBlockMode || !editorSDK || editorSDK?.getActivePageIdx() !== pageInfo.pageIndex) return
    const scrollDOM = document.querySelector<HTMLDivElement>('.canvas_content_box.scroll') || document.querySelector<HTMLDivElement>('#id-canvas-scroll')
    const handleScroll = () => {
      let canceled = false
      if (!editorSDK || editorSDK?.getActivePageIdx() !== pageInfo.pageIndex) return
      /** 取消选中 */
      const cancelable = [activeRowIdx, activeCellIdx, editingElemId].some(item => {
        return typeof item !== 'undefined'
      })
      if (cancelable && !canceled) {
        console.log('cancel selected state from scroll')
        canceled = true
        editorSDK?.changeWidgetState({
          activeRowIdx: undefined,
          activeCellIdx: undefined,
          editingElemId: undefined,
          isActiveGrid: false,
        })
      }
    }
    scrollDOM?.addEventListener('scroll', handleScroll)

    return () => {
      scrollDOM?.removeEventListener('scroll', handleScroll)
    }
  }, [activeRowIdx, activeCellIdx, editingElemId])


  if (!Array.isArray(cellsMap)) {
    return <>MkGrid渲染错误</>
  }

  /** 激活的深度 */
  const activeDeep = [activeRowIdx, activeCellIdx, editingElemId].reduce<number>((acc, curr) => curr !== undefined ? (acc || 0) + 1 : acc, 0)

  const GridDOM = (
    <div className={clas(
      "MkGrid",
      `activeDeep_${activeDeep}`,
      interactive && 'interactive',
      isPc() && 'hover_mode',
      fullStack ? 'fullStack' : 'simple',
    )}
      onClick={(e) => {
        if (!editorSDK) return
        e.preventDefault()
        e.stopPropagation()
        editorSDK?.changeWidgetState({
          activeRowIdx: undefined,
          activeCellIdx: undefined,
          editingElemId: undefined,
          isActiveGrid: false,
        })
      }}
      ref={MkGridRef}
      id={`MkGrid_${id}`}
      style={blockStyleFilter({
        display: 'flex',
        flexDirection: 'column',
        ...gridStyle,
        // width: containerInfo.width,
        // height: containerInfo.height,
      })}
    >
      {fullStack && (
        <Indicator
          title="卡片"
          domID={`#MkGrid_${id}`}
        />
      )}
      {interactive && (
        <div className={clas(`grid_more_options_container`, isActiveGrid && 'active')}></div>
      )}
      {
        cellsMap.map((currRow, rowIndex) => {
          const isActiveRow = activeRowIdx === rowIndex && !editingElemId && typeof activeCellIdx === 'undefined'
          return (
            <div
              className={clas("MkRow", isActiveRow && 'active', `row_${rowIndex}`)}
              key={rowIndex}
              onClick={(e) => {
                if (!editorSDK || !fullStack) return
                e.preventDefault()
                e.stopPropagation()
                editorSDK?.changeWidgetState({
                  activeRowIdx: rowIndex,
                  isActiveGrid: false,
                })
              }}
              style={blockStyleFilter({
                // flex: '1',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                ...(currRow.style || {}),
                // alignItems: 'start',
              })}
            >
              {interactive && (
                <div className={`more_options_container`}></div>
              )}
              {fullStack && (
                <Indicator
                  title="行"
                  domID={`#MkGrid_${id} .row_${rowIndex}`}
                />
              )}
              {
                currRow.cells && currRow.cells.map((currCol, colIndex) => {
                  const cellId = `${rowIndex}-${colIndex}`
                  const isActive = (activeRowIdx === rowIndex) && (colIndex === activeCellIdx) && !editingElemId
                  const hasChildren = currCol.childrenIds?.length

                  const itemDOM = (
                    <div className={clas("MkGrid_item", isActive && 'active', `cell_${colIndex}`)}
                      style={blockStyleFilter({
                        // display: 'grid',
                        // gridTemplate: `1fr`,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: currCol.style?.gap || 0,
                        flexGrow: currCol.style?.flexGrow || 1,
                        flexBasis: currCol.style?.flexBasis || 0,
                        minHeight: !hasChildren ? 50 : undefined,
                        alignItems: currCol.style?.alignItems || 'center',
                        ...(currCol.style || {}),
                      })}
                      data-id={cellId}
                      key={cellId}
                      onClick={(e) => {
                        if (!editorSDK) return
                        e.preventDefault()
                        e.stopPropagation()
                        editorSDK?.changeWidgetState({
                          activeCellIdx: colIndex,
                          activeRowIdx: rowIndex,
                          editingElemId: undefined,
                          isActiveGrid: false,
                        })
                      }}
                    >
                      {interactive && (
                        <div className={`more_options_container`}></div>
                      )}
                      {fullStack && (
                        <Indicator
                          title="单元格"
                          domID={`#MkGrid_${id} .row_${rowIndex} .cell_${colIndex}`}
                        />
                      )}
                      {
                        currCol.childrenIds?.map((elemId, index) => {
                          let compEntity: any = null
                          const layer = getLayer(elemId)
                          const isSelected = layer?.elemId === editingElemId

                          if (!layer || !layer.elementRef) return compEntity

                          const { elementRef, attrs: currAttr } = layer

                          if (elementRef === 'MkPicture') {
                            compEntity = (
                              <MkImgLiteComp attrs={currAttr as any} editorSDK={editorSDK as any} elemId={layer.elemId} />
                            )
                          } else {
                            const Comp = LoadWidget(layer.elementRef)
                            if (!Comp) {
                              console.log('组件加载失败', layer.elementRef)
                            } else {
                              compEntity = (
                                <Comp
                                  {...props}
                                  id={layer.elemId}
                                  worksType={worksType}
                                  editorCtx={editorCtx}
                                  editorSDK={editorSDK}
                                  canvaInfo={canvaInfo}
                                  containerInfo={{
                                    width: 'auto',
                                    height: 'auto',
                                  }}
                                  controledValues={{ ...layer.attrs, flowMode: true }}
                                  pageInfo={pageInfo}
                                  lifecycle={{
                                    didMount: (data) => editorSDK?.didMount?.(layer.elemId, data),
                                    didLoaded: () => { },
                                  }}
                                />
                              )
                            }
                          }

                          const selectItem = (e) => {
                            if (!editorSDK) return
                            e.preventDefault()
                            e.stopPropagation()
                            if (editingElemId === layer.elemId) return
                            editorSDK?.changeWidgetState({
                              isActiveGrid: false,
                              activeCellIdx: colIndex,
                              activeRowIdx: rowIndex,
                              editingElemId: layer.elemId,
                            })
                          }

                          return (
                            <div
                              key={layer.elemId}
                              onClick={selectItem}
                              // onClick={!isIOS() ? selectItem : undefined}
                              // onTouchEnd={isIOS() ? selectItem : undefined}
                              className={clas("layer_item", isSelected && 'active', `layer_${layer.elemId}`)}>
                              {interactive && (
                                <>
                                  <div className={`more_options_container`}></div>
                                  <div className={`add_item_container`}></div>
                                </>
                              )}
                              {fullStack && (
                                <Indicator
                                  title="元素"
                                  domID={`#MkGrid_${id} .row_${rowIndex} .cell_${colIndex} .layer_${layer.elemId}`}
                                />
                              )}
                              <div className="" style={{
                                pointerEvents: elementRef !== 'MkText' && editorSDK ? 'none' : undefined,
                              }}>
                                {compEntity}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )

                  return itemDOM
                })
              }
            </div>
          )
        })
      }
      {/* {renderGridMoreOptionsDOM()} */}
    </div>
  )

  return GridDOM
}

export default MkGrid
