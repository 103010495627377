import { isPc } from "@mk/utils"

/** 获取移动设备屏幕宽度 */
const getMobileWidth = () => {
  return Math.min(document.documentElement.clientWidth, 750)
}
export const getWorksPadding = (str) => {
  if (!str) return
  let scaleRate = getMobileWidth() / 375
  if (isPc()) {
    scaleRate = 1
  }
  const padding = String(str || "")
  const paddingList = padding?.split(" ") || []
  /** 根据屏幕宽度和375的比例缩放，然后返回padding */
  const getPadding = (val) => {
    return `${+val * scaleRate}px`
  }
  paddingList.forEach((item, index) => {
    paddingList[index] = getPadding(item.replace(/px/gi, ""))
  })
  return paddingList.join(" ")
}

export const blockStyleFilter = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    padding: getWorksPadding(style?.padding),
    margin: getWorksPadding(style?.margin),
    gap: getWorksPadding(style?.gap),
    borderRadius: getWorksPadding(style?.borderRadius),
    borderWidth: getWorksPadding(style?.borderWidth),
    outlineWidth: getWorksPadding(style?.outlineWidth),
  }
}