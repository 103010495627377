import React, { useEffect, useRef, useState } from 'react'
import { cdnApi } from '@mk/services'
import { EditorSDK } from '@mk/widgets-bridge-sdk/types/editor-sdk'
import { MkPictureData } from './types'
import { blockStyleFilter, deepClone } from "../shared/utils"
import { MkGridProps, MkGridState } from '../shared'

interface MkImgLiteCompProps {
  attrs: MkPictureData
  editorSDK: EditorSDK<MkGridProps, MkGridState>
  elemId: string
}

const calcClipPath = (mask, width: number) => {
  if (!mask?.content) {
    return ''
  }
  const path = mask.content
  const pathWidth = mask.width || 38
  if (path.indexOf("clip-path") > -1) {
    const clipPath = path.replace("clip-path: ", "")
    return clipPath.replace(/;/g, "")
  } else {
    const WIDTH = width / pathWidth
    const PRECISION = 1
    if (WIDTH && path && PRECISION) {
      const output = path.replace(/([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)/g, (match) => {
        return (+match * WIDTH).toFixed(PRECISION)
      })
      return `path("${output}")`
    }
  }
  return ''
}

const getOssCropQuery = (ossPath, cropData?, resize = 640) => {
  const query = cropData ? `?x-oss-process=image/crop,x_${(cropData.left).toFixed(0)},y_${(cropData.top).toFixed(0)},w_${(cropData.width).toFixed(0)},h_${(cropData.height).toFixed(0)}/resize,w_${resize}` : ''
  return `${cdnApi(ossPath)}${query}`
}

export default function MkImgLiteComp({ attrs, editorSDK, elemId }: MkImgLiteCompProps) {
  const { ossPath, lockRatioV2, cropData, mask } = attrs
  const isVideo = ossPath?.includes('mov') || ossPath?.includes('mp4')
  const ossPathPrev = useRef(ossPath)
  const [imgInfo, setImgInfo] = useState<HTMLImageElement>()
  const containerRef = useRef<HTMLDivElement>(null)
  const originImgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    /** 换图 */
    if (ossPathPrev.current !== ossPath) {
      const img = new Image()
      img.src = cdnApi(ossPath)
      img.onload = () => {
        ossPathPrev.current = ossPath
        const nextData = {
          baseW: img.width,
          baseH: img.height,
          originBaseW: img.width,
          originBaseH: img.height,
          cropData: {
            left: 0,
            top: 0,
            width: img.width,
            height: img.width / +lockRatioV2,
            // height: img.height / (+lockRatioV2 || 1),
          }
        }
        editorSDK?.changeCompAttr(elemId, nextData)
      }
      img.onerror = () => {
      }
    }
  }, [ossPath])

  useEffect(() => {
    if (!imgInfo) {
      const img = new Image()
      img.src = cdnApi(ossPath)
      img.onload = () => setImgInfo(img)
    }

    return () => {
    }
  }, [imgInfo])


  useEffect(() => {
    if (imgInfo && (!attrs.baseW || attrs.cropData.width === 375)) {
      const _lockRatioV2 = +lockRatioV2 || +(imgInfo.width / imgInfo.height).toFixed(5)
      const nextData = {
        lockRatioV2: _lockRatioV2,
        baseW: imgInfo.width,
        baseH: imgInfo.height,
        originBaseW: imgInfo.width,
        originBaseH: imgInfo.height,
        cropData: {
          left: 0,
          top: 0,
          width: imgInfo.width,
          height: imgInfo.width / _lockRatioV2,
        }
      }
      editorSDK?.changeCompAttr(elemId, nextData)
    }
  }, [attrs.lockRatioV2, imgInfo])
  // console.log('cropData', cropData)
  const disableCrop = cropData?.width === 375 && !editorSDK

  return (
    <div
      ref={containerRef}
      data-id={elemId}
      style={blockStyleFilter({
        ...attrs,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      } as React.CSSProperties)}>
      {
        isVideo ? (
          <video width="100%" controls style={{
            pointerEvents: 'none'
          }}>
            <source src={cdnApi(ossPath)} type="video/mp4" />
          </video>
        ) : (
          <>
            <div
              style={{
                clipPath: calcClipPath(mask, originImgRef.current?.width || 0)
              }}
            >
              <img
                className='origin_img'
                ref={originImgRef}
                style={{
                  // pointerEvents: 'none',
                  display: 'block',
                  maxWidth: '100%',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  // opacity: 0,
                  aspectRatio: lockRatioV2 ? `${lockRatioV2}` : undefined
                }}
                src={disableCrop ? getOssCropQuery(ossPath) : getOssCropQuery(ossPath, cropData)} alt="" />
            </div>
          </>
        )
      }
    </div>
  )
}
