import { isPc, random } from "@mk/utils"
import { LayerElemItem, PositionLinkMap } from "@mk/works-store/types"
import { deepLayers } from "@mk/works-store/utils/deepLayers"
import { MkGridProps } from "."

export * from './styleHelper'

export const deepClone = <T>(obj: T): T => {
  if(!obj) return obj
  return JSON.parse(JSON.stringify(obj))
}

export const getCopyPageData = ({ editorSDK, pageInfo }) => {
  const currPageData = editorSDK?.getPageData(pageInfo.pageIndex)
  currPageData.id = random()
  const layerIdMap: Record<string, { newId: string; elemRef: string }> = {}
  const { layers } = currPageData
  const idContrastMap: Record<
    string,
    { newId: string; oldParentId?: string; newParentId?: string }
  > = {}

  let newGridLayer = {} as LayerElemItem

  deepLayers(layers, (item) => {
    const newId = random()
    const { elemId } = item
    if (elemId != null) {
      const oldParentId = item.parentId
      const newParentId = idContrastMap[item.parentId || ""]?.newId
      idContrastMap[elemId] = { newId, oldParentId, newParentId }
      item.elemId = newId
      item.parentId = newParentId

      layerIdMap[elemId] = {
        newId,
        elemRef: item.elementRef,
      }

      if (item.elementRef === "MkGrid") {
        newGridLayer = item
      }
    }
  })

  const positionLink: PositionLinkMap = {}
  Object.entries(idContrastMap).forEach((item) => {
    const [oldId, newObj] = item
    const { newId, newParentId } = newObj
    positionLink[newId] = editorSDK.getLink(oldId)
    positionLink[newId].parentId = newParentId
  })

  const nextCellsMap = newGridLayer.attrs.cellsMap as MkGridProps["cellsMap"]
  nextCellsMap.forEach((rowItems, rIdx) => {
    const currRow = nextCellsMap[rIdx].cells
    currRow.forEach((colItem, colIdx) => {
      nextCellsMap[rIdx].cells[colIdx].childrenIds?.forEach((childId) => {
        const newId = layerIdMap[childId]?.newId
        const oldChildIdx = nextCellsMap[rIdx].cells[colIdx].childrenIds?.indexOf(childId)
        if (typeof oldChildIdx === "undefined" || oldChildIdx === -1) return
        newGridLayer.attrs.cellsMap[rIdx].cells[colIdx].childrenIds?.splice(oldChildIdx, 1, newId)
      })
    })
  })

  return {
    pageData: currPageData,
    positionLink,
  }
}

export const countArray = (arr?: any[]) => {
  if (!Array.isArray(arr)) return -1
  const newArr = deepClone(arr).filter(Boolean)
  return newArr.length
}

export const cleanGridProps = (gridProps: MkGridProps['cellsMap']) => {
  /** 移除 row, cell, children 中的空节点 */
  const nextProps = deepClone(gridProps)?.map((row) => {
    row.cells = row.cells.map((cell) => {
      cell.childrenIds = cell.childrenIds?.filter(Boolean)
      return cell
    }).filter(Boolean)
    return row
  }).filter(Boolean)
  
  return nextProps
}
